.wa_notification {
  position: relative;
  font-size: 2.7rem;
}
.wa_notificationBadge {
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  background: #ff7c7c;
  border: 0.139rem solid #47bbe1;
  position: absolute;
  top: 0;
  right: 0;
}
