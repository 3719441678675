.na_menu {
  width: 21%;
  min-width: 21%;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
}

.na_menu_title {
  font-size: 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.na_menu_searchIcon {
  font-size: 2rem;
  opacity: 40%;
  cursor: pointer;
}

.na_menu_newNoteBtn {
  margin-top: 3rem;
  color: white;
  background: rgba(255, 255, 255, 5%);
  border: none;
  padding: 1rem 0;
}

.na_menu_newNoteBtn:hover {
  opacity: 75%;
}

.na_menu_newNoteBtn::before {
  content: "+";
  margin-right: 1rem;
}

.na_menu_searchBox {
  margin-top: 3rem;
  background: rgba(255, 255, 255, 5%);
  border: none;
  padding: 1rem;
}

.na_menu_searchBox span {
  margin-right: 0.8rem;
  color: inherit;
}

.na_menu_searchBox input {
  color: inherit;
  background: rgba(255, 255, 255, 0%);
  border: none;
  padding: 0;
}

.na_menu_menuGroupsContainer {
  overflow-x: auto;
}

.na_menu_group__labelIcon {
  font-size: 2rem;
  cursor: pointer;
}
