.slider-boxThumb {
  height: 36px;
  -webkit-appearance: none;
}
.slider-boxThumb:focus {
  outline: none;
}
.slider-boxThumb::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #a6a6a6;
  background: #00f0ff;
  border-radius: 0px;
  border: 0px solid #00f0ff;
}
.slider-boxThumb::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #a6a6a6;
  border: 0px solid #f8ef00;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #f8ef00;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
.slider-boxThumb:focus::-webkit-slider-runnable-track {
  background: #00f0ff;
}
.slider-boxThumb::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #a6a6a6;
  background: #00f0ff;
  border-radius: 0px;
  border: 0px solid #00f0ff;
}
.slider-boxThumb::-moz-range-thumb {
  box-shadow: 0px 0px 0px #a6a6a6;
  border: 0px solid #f8ef00;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #f8ef00;
  cursor: pointer;
}
.slider-boxThumb::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.slider-boxThumb::-ms-fill-lower {
  background: #00f0ff;
  border: 0px solid #00f0ff;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #a6a6a6;
}
.slider-boxThumb::-ms-fill-upper {
  background: #00f0ff;
  border: 0px solid #00f0ff;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #a6a6a6;
}
.slider-boxThumb::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #a6a6a6;
  border: 0px solid #f8ef00;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #f8ef00;
  cursor: pointer;
}
.slider-boxThumb:focus::-ms-fill-lower {
  background: #00f0ff;
}
.slider-boxThumb:focus::-ms-fill-upper {
  background: #00f0ff;
}
