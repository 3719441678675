.na_details__default {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.na_details_defaultIcon {
  font-size: 8rem;
}

.na_details_defaultTitle {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.5rem;
}

.na_details_defaultHint {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: var(--fnt-color-defaultHint);
  width: 58%;
  text-align: center;
  margin-top: 1rem;
}
