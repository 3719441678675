@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400&family=Tomorrow:wght@400;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

:root {
  --color-yellow: #f8ef00;
  --color-aqua-bright: #00f0ff;
}

.pg_btn {
  cursor: pointer;
}

.pg_btn:hover {
  opacity: 75%;
}

.pg_container {
  padding: 8.5% 10.7%;
  background-color: black;
  color: white;
  height: 100%;
  width: 100%;
  font-family: "Tomorrow", sans-serif;
  letter-spacing: 0.2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pg_generator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 41%;
}

.pg_history {
  width: 41%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pg_heading {
  font-size: 4rem;
  color: var(--color-yellow);
  font-weight: 700;
}

.pg_heading2 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.2em;
  align-self: flex-start;
}

.pg_password_output {
  display: flex;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: 1px solid var(--color-aqua-bright);
  background: rgba(0, 240, 255, 0.1);
  padding: 2rem;
  letter-spacing: 0.2em;
  min-width: 100%;
  color: var(--color-aqua-bright);
  width: 100%;
}

.pg_password_output_text {
  width: calc(100% - 1.6rem);
  overflow: hidden;
  text-overflow: ellipsis;
}

.pg_password_output_refresh {
  margin-left: auto;
}

.pg_copyPassword {
  display: flex;
  margin-top: 2rem;
  background-color: var(--color-yellow);
  max-width: 100%;
  min-width: 22rem;
  border: none;
  border-right: 2px solid red;
  padding: 2rem 4rem;
  font-weight: 600;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%, 0% 75%);
  letter-spacing: 0.2em;
}

.pg_right_arrow {
  margin-left: auto;
}

.pg_charCountValue {
  margin-top: 6.4rem;
}

.pg_charCountSlider {
  display: inline-block;
  min-width: 100%;
  background-color: black;
}

.pg_options {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6.4rem;
  row-gap: 2.4rem;
}

.pg_options_option {
  width: 50%;
}

.pg_pwdRecords {
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  row-gap: 2rem;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
}

.pg_pwdRecordLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pg_pwdRecordSet {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.2em;
}

.pg_pwdRecord {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.pg_pwdRecordDate {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.pg_pwdRecordCopy {
  font-size: 2.4rem;
}

.pg_clearHistory {
  margin-top: 2.4rem;
  border: none;
  background: none;
  color: var(--color-yellow);
  font-weight: 400;
  line-height: 2.4rem;
}

@media screen and (max-width: 800px) {
  .pg_container {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    padding: 4.8rem 2rem;
  }

  .pg_generator {
    width: 100%;
  }

  .pg_history {
    width: 100%;
    margin-top: 6.4rem;
  }
  .pg_copyPassword {
    width: 100%;
  }
}
