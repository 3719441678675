:root {
  --bg-color-cntnr: #daf0ff;
  --bg-color-calc: #f7f8fb;
  --bg-color-shadow: rgba(96, 190, 255, 0.55);
  --fnt-color-input: #818181;
  --fnt-color-ans: #424242;
  --fnt-color-action-dark: #858585;
  --fnt-color-action-blue-dark: #109dff;
  --bg-color-action-blue-dark: #ade2ff;
  --fnt-color-digits: #38b9ff;
  --bg-color-action-blue-equal: #19acff;
  --fnt-color-action-blue-equal: #b2daff;
}

.calc_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bg-color-cntnr);
}

.calc_main {
  max-height: 812px;
  height: 100%;
  max-width: 375px;
  width: 100%;
  border-radius: 3.9rem;
  box-shadow: 12.3rem 10.5rem 10rem var(--bg-color-shadow);
  display: flex;
  flex-direction: column;
}

.calc_main_light {
  background: var(--bg-color-calc);
}

.calc_input {
  margin: auto 2.5rem 0;
  font-size: 2.4rem;
  text-align: end;
  color: var(--fnt-color-input);
}

.calc_ans {
  margin: 0.6rem 1.8rem 0;
  font-size: 4.8rem;
  text-align: end;
  color: var(--fnt-color-ans);
  overflow-wrap: anywhere;
}

.action_line_1 {
  margin: 1.6rem 3.3rem 0;
  display: flex;
  justify-content: space-between;
  font-size: 3.2rem;
}

.action_line_1 span,
.action_line_2 span {
  width: 6.2rem;
  height: 6.2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.action_font_dark {
  color: var(--fnt-color-action-dark);
}

.action_font_blue_dark {
  color: var(--fnt-color-action-blue-dark);
  background: var(--bg-color-action-blue-dark);
  border-radius: 1.2rem;
}

.digit_n_action_line {
  margin: 2.2rem 3.3rem;
  display: flex;
}

.digit_group {
  flex-basis: 0;
  flex-grow: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.6rem;
  font-size: 3.2rem;
  color: var(--fnt-color-digits);
}

.digit_group span {
  width: 6.2rem;
  height: 6.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.digit_group .digit_zero {
  width: 14.4rem;
}

.action_line_2 {
  flex-basis: 0;
  flex-grow: 1;
  margin-left: 2rem;
  width: 6.2rem;
  max-width: 6.2rem;
  font-size: 3.2rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.long_btn {
  margin-top: 2.2rem;
  height: 9.6rem;
  min-height: 9.6rem;
}

.action_font_blue_gloss {
  color: var(--fnt-color-action-blue-equal);
  background: var(--bg-color-action-blue-equal);
  border-radius: 1.2rem;
  box-shadow: -0.6rem 0.8rem 3rem var(--bg-color-action-blue-equal);
}
