@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.12/css/weather-icons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/weather-icons/2.0.12/css/weather-icons-wind.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

:root {
  --sunClouded-background: linear-gradient(
    167.44deg,
    #29b2dd 0%,
    #33aadd 47.38%,
    #2dc8ea 100%
  );
  --sunClouded-contentBg: #1040844c;
  --rainy-background: linear-gradient(
    167.44deg,
    #08244f 0%,
    #134cb5 47.38%,
    #0b42ab 100%
  );
  --rainy-contentBg: #0010264c;
}

.wa_main {
  width: 100%;
  min-height: 100%;
  padding: 4.4rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
}

.wa_main--sunClouded {
  background: var(--sunClouded-background);
}

.wa_main--rainy {
  background: var(--rainy-background);
}

.wa_contentBg--sunClouded {
  background-color: var(--sunClouded-contentBg);
}

.wa_contentBg--rainy {
  background-color: var(--sunClouded-rainy);
}

.wa_weatherOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wa_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.wa_location {
  display: flex;
  align-items: center;
}

.wa_locationIcon {
  font-size: 2.4rem;
}

.wa_locationText_autocomplete input {
  border: none;
  background: none;
  outline: none;
  color: inherit;
  margin-left: 1.2rem;
  margin-right: 2rem;
  line-height: 21px;
}

.wa_locationText_autocomplete input,
.wa_locationText_autocomplete * {
  font-size: 1.8rem;
  font-weight: 600;
}

.wa_loadingSpinner {
  font-size: 2rem;
  margin-right: 1rem;
}

.wa_locationText_select option {
  color: black;
}

.wa_weatherIcon {
  font-size: 10rem;
}

.wa_weatherDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wa_temperature {
  font-weight: 600;
  font-size: 6.4rem;
  line-height: 7.6rem;
}

.wa_moreStats {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  padding: 1rem;
  border-radius: 2.2rem;
  margin-top: 3.1rem;
  width: 32rem;
  max-width: 32rem;
}

.wa_moreStats i {
  font-size: 1.6rem;
}

.wa_moreStats > span {
  display: flex;
  column-gap: 1rem;
  padding: 0.5rem 1.05rem;
  white-space: nowrap;
}

.wa_todayStats,
.wa_weekForecast {
  width: 32rem;
  max-width: 32rem;
  padding: 1.2rem 1.4rem 1.6rem;
  border-radius: 2.2rem;
  margin-top: 2rem;
}

.wa_todayStats_header,
.wa_weekForecast_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;
}

.wa_todayStats_header_label,
.wa_weekForecast_header_label {
  font-size: 2rem;
  font-weight: 700;
}

.wa_todayStats_hourly {
  display: flex;
  justify-content: space-between;
}

.wa_todayStats_cardWeather {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
  padding: 1.3rem 1.2rem 2rem;
  font-size: 1.8rem;
  width: 25%;
}

.wa_todayStats_cardWeather_weather {
  font-size: 4rem;
}

.wa_weekForecast_day {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1.4rem 0;
}

.wa_weekForecast_dayName {
  width: 8.3rem;
}

.wa_weekForecast_temperature {
  display: flex;
  column-gap: 1rem;
}

@media screen and (min-width: 768px) {
  .wa_main {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
