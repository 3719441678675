@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

:root {
  --bg-color-home: #c4acc9;
  --bg-color-pink: #9c27b0;
  --hover-color-light: rgb(173, 232, 244);
}

.rps_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bg-color-home);
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.rps_main {
  max-height: 568px;
  height: 100%;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rps_main__home {
  background-color: var(--bg-color-pink);
  color: white;
  padding-top: 1.8rem;
}

.rps_main__gameStarted {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.rps_main_resultPage {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.rps_title {
  font-size: 4.5rem;
  text-align: center;
  margin: 0 20rem;
}

.rps_image {
  max-height: 26.4rem;
  max-width: 14.7rem;
}

.rps_startBtn {
  margin: 0 3rem 2.7rem;
  font-size: 1.8rem;
  width: calc(100% - 6rem);
  height: 5rem;
  border-radius: 1rem;
  border: none;
  font-weight: 300;
  margin-top: 1.6rem;
}

.rps_startBtn:hover {
  background: var(--hover-color-light);
}

.rps_back_icon {
  padding: 1.5rem;
  align-self: flex-start;
  cursor: pointer;
}

.rps_back_icon:hover {
  background: var(--hover-color-light);
}

.rps_handOpponent {
  height: 20rem;
}

.rps_letsPlay {
  font-size: 4rem;
  margin-top: 5rem;
  font-weight: 900;
}

.rps_pickAnOption {
  font-size: 1.8rem;
  font-weight: 300;
  margin-top: 5.9rem;
}

.rps_action_container {
  display: flex;
  padding: 1.5rem;
  width: calc(100% - 3rem);
  justify-content: space-between;
}

.rps_action {
  background-color: var(--bg-color-pink);
  width: 6.8rem;
  height: 6.8rem;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 2rem;
}

.rps_action:hover {
  opacity: 75%;
}

.rps_action_icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  bottom: 10px;
}

.rps_action_stone {
  background: url("../assets/images/rps/hand_stone.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: 8px;
}

.rps_action_scissor {
  background: url("../assets/images/rps/hand_scissor.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_action_paper {
  background: url("../assets/images/rps/hand_paper.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_opponent {
  width: 80%;
  height: 17rem;
  transform: rotate(-180deg);
}

.rps_opponent_stone {
  background-image: url("../assets/images/rps/hand_stone.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_opponent_scissor {
  background-image: url("../assets/images/rps/hand_scissor.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_opponent_paper {
  background-image: url("../assets/images/rps/hand_paper.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_result {
  font-size: 2.5rem;
  font-weight: 900;
  margin-top: 1.5rem;
}

.rps_btnPlayAgin {
  margin: 0 3rem 2.7rem;
  font-size: 1.8rem;
  border-radius: 1rem;
  border: none;
  font-weight: 300;
  margin-top: 1rem;
  background-color: var(--bg-color-pink);
  color: white;
  padding: 1.25rem 4.25rem;
}

.rps_btnPlayAgin:hover {
  opacity: 75%;
}

.rps_userAction {
  width: 80%;
  height: 17rem;
}

.rps_userAction_stone {
  background-image: url("../assets/images/rps/hand_stone.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_userAction_scissor {
  background-image: url("../assets/images/rps/hand_scissor.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rps_userAction_paper {
  background-image: url("../assets/images/rps/hand_paper.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
