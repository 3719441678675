@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

:root {
  --bg-color-blue-container: #a2d2ff;
  --bg-color-blue-light: #00d2ff;
  --bg-color-blue-dark: #3a7bd5;
  --bg-color-grid: #c1c1c1;
}

.ttt_btn {
  cursor: pointer;
}

.ttt_btn:hover {
  opacity: 75%;
}

.ttt_container {
  background: var(--bg-color-blue-container);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ttt_main {
  color: white;
  background-image: linear-gradient(
    150deg,
    var(--bg-color-blue-light) 0%,
    var(--bg-color-blue-dark) 100%
  );
  min-width: 375px;
  min-height: 667px;
  max-width: 100%;
  max-height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ttt_main__home {
  background-image: url(../assets/images/ttt/bg_cross.svg),
    url(../assets/images/ttt/bg_circle.svg),
    linear-gradient(
      150deg,
      var(--bg-color-blue-light) 0%,
      var(--bg-color-blue-dark) 100%
    );
  background-position: 0 8.3rem, 20.4rem 0, 0 0;
  background-repeat: no-repeat, no-repeat, no-repeat;
}

.ttt_title {
  font-weight: 700;
  font-size: 4.2rem;
  line-height: 5.1rem;
  text-align: center;
  margin: 20.8rem 5rem 0;
}

.ttt_pickTurn {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
  margin-top: 17.3rem;
}

.ttt_symbolChooseFirst {
  display: flex;
  width: calc(100% - 6.4rem);
  justify-content: space-between;
  align-items: center;
  margin: 2.1rem 3.2rem;
}

.ttt_symbolOption {
  background-color: white;
  padding: 2.7rem;
  border-radius: 2rem;
}

.ttt_symbolOption img {
  width: 9rem;
  height: 9rem;
}

.ttt_timer {
  font-weight: 500;
  font-size: 3.4rem;
  line-height: 4.1rem;
  background: white;
  color: black;
  border-radius: 10rem;
  padding: 0.7rem 3.7rem;
  margin-top: 7.1rem;
}

.ttt_playersTurnInfo {
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.4rem;
  margin-top: 2.3rem;
}

.ttt_gameArea {
  width: 324px;
  height: 324px;
  background: white;
  border-radius: 2rem;
  margin-top: 5rem;
  padding: 2.8rem;
}

.ttt_gameArea__containerGridMaker {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: repeat(3, 33%) / repeat(3, 33%);
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 0.2rem;
  background: var(--bg-color-grid);
}

.ttt_gameAreaUnit {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: black;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ttt_gameResultContainer {
  position: fixed;
}
.ttt_gameResult {
  width: 324px;
  height: 220px;
  position: fixed;
  background: #00b4d8;
  padding: 7rem 2rem;
  top: 43%;
  left: 33.2%;
  font-size: 4rem;
  box-shadow: 0px 0px 77px 11px rgba(0, 0, 0, 1);
  border-radius: 9px 9px 9px 9px;
  border: 10px solid #0077b6;
  top: 50%;
  left: 50%;
  margin-top: -110px;
  margin-left: -162px;
  text-align: center;
}
