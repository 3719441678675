.na_details_noteMenuContainer {
  position: relative;
  bottom: 0.5rem;
}

.na_details_saveIcon {
  margin-left: auto;
  margin-right: 1rem;
  opacity: 40%;
  cursor: pointer;
}

.na_details_menuIcon {
  display: inline-block;
  font-size: 2rem;
  height: 3.2rem;
  width: 3.2rem;
  border: 1px solid white;
  border-radius: 3.2rem;
  text-align: center;
  cursor: pointer;
  opacity: 40%;
}

.na_details_menuIcon:hover {
  opacity: 100%;
}

.na_details_noteMenu {
  position: absolute;
  background: #333333;
  width: 20.2rem;
  top: 6rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  row-gap: 2rem;
  opacity: 100%;
  z-index: 1;
  border-radius: 0.6rem;
}

.na_details_noteMenu_item {
  cursor: pointer;
}

.na_details_noteMenu_item:hover {
  background-color: var(--color-blue);
  width: 100%;
}

.na_details_noteMenu_itemText {
  margin-left: 1.5rem;
}
