.home_page {
  padding-top: 4.8rem;
}

.home-page-thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.app-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .home-page-thumbnail {
    width: 150px;
    height: 150px;
  }

  .app-details {
    flex-direction: row;
    justify-content: space-between;
    max-width: 650px;
    width: 100%;
  }

  .description {
    max-width: 50%;
    max-height: 250px;
  }
}
